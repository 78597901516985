import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTabTitle } from '../../hooks/useTabTitle/useTabTitle';
import { PageHeaderProps } from './PageHeader.model';
import classes from './PageHeader.scss';
import { PageHeaderActionProps } from './PageHeaderAction';
import { PageHeaderAction } from './PageHeaderAction/PageHeaderAction';
import { PageHeaderActionsGroup } from './PageHeaderActionsGroup/PageHeaderActionsGroup';
import { PageHeaderActionsGroupContextProvider } from './PageHeaderActionsGroup/PageHeaderActionsGroupsContextProvider';
import { useElementWidthObserver } from './helpers/useElementWidthObserver';

/**
 * Primary header for stations. Accepts a title, subtitle, actions, and actions groups.
 * @example
 * <PageHeader title="title" subtitle="subtitle" actions={[
 *  { label: 'Undo', icon: IconName.Undo, kind: 'action', onClick: onActionSelected },
 *  { label: 'Cancel', icon: IconName.X, kind: 'action' onClick: onActionSelected },
 *  { kind: 'spacer' },
 *  { label: 'Actions Group', kind: 'group', actions: [
 *    { label: 'Action 1', actionType: PageHeaderActionType.Context, icon: IconName.Delete, confirmationMode: 'Advanced', onClick: onActionSelected },
 *  ]}
 * ]} />
 */
export const PageHeader: React.FC<PageHeaderProps> = ({
  title = '',
  subtitle = '',
  actions = [],
  className = '',
  setTabTitle = true,
}) => {
  useTabTitle(title, setTabTitle);
  const { width, ref } = useElementWidthObserver<HTMLDivElement>();

  const [availableActionSpace, setAvailableActionSpace] = useState<number>(0);

  useEffect(() => {
    // Use up to 75% of the container width for actions
    const maxActionsWidth = width * 0.75;

    // Each action is 120px wide
    setAvailableActionSpace(Math.floor(maxActionsWidth / 120 - actions.length));
  }, [width, actions.length]);

  return (
    <div
      className={clsx(classes.container, 'page-header-container', className)}
      data-test-id="page-header"
      ref={ref}
    >
      <div className={clsx(classes.titles)}>
        <div className={clsx(classes.title)} data-test-id="page-header-title">
          {title}
        </div>
        <div
          title={subtitle}
          className={clsx(classes.subtitle)}
          data-test-id="page-header-subtitle"
        >
          {subtitle}
        </div>
      </div>
      <div className={classes.actions} data-test-id="page-header-actions">
        <PageHeaderActionsGroupContextProvider>
          {actions.map((action, index) => {
            switch (action.kind) {
              case 'group':
                return (
                  <PageHeaderActionsGroup
                    key={index}
                    {...action}
                    availableActionSpace={availableActionSpace}
                  />
                );
              case 'spacer':
                return <div key={index} className={classes.spacer} />;
              case 'action':
                return (
                  <PageHeaderAction
                    key={index}
                    {...(action as PageHeaderActionProps)}
                  />
                );
            }
          })}
        </PageHeaderActionsGroupContextProvider>
      </div>
    </div>
  );
};

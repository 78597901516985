import React, { useCallback, useState } from 'react';
import { PageHeaderActionsGroupContext } from './PageHeaderActionsGroupsContext';

type CollapseAction = Record<string, () => void>;

export const PageHeaderActionsGroupContextProvider: React.FC = ({
  children,
}) => {
  const [registeredCollapses, setRegisteredCollapses] =
    useState<CollapseAction>({});

  const registerCollapse = useCallback(
    (collapse: () => void, uuid: string): void => {
      setRegisteredCollapses((prev) => ({ ...prev, [uuid]: collapse }));
    },
    [],
  );

  const collapseAll = useCallback(() => {
    Object.values(registeredCollapses).forEach((collapse) => collapse());
  }, [registeredCollapses]);

  return (
    <PageHeaderActionsGroupContext.Provider
      value={{ registerCollapse, collapseAll }}
    >
      {children}
    </PageHeaderActionsGroupContext.Provider>
  );
};

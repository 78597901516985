import clsx from 'clsx';
import { LocationDescriptor } from 'history';
import React, { PropsWithChildren, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { noop } from '../../../helpers/utils';
import { Data } from '../../../types/data';
import { getTooltipText } from '../../../utils/ToolTipHelpers';
import { ActionData } from '../../Actions/Actions.models';
import { Button } from '../../Buttons';
import { IconName } from '../../Icons';
import { InlineMenu } from '../../InlineMenu/InlineMenu';
import { Column, ListSelectMode } from '../List.model';
import { ListCheckBox } from '../ListCheckBox/ListCheckBox';
import classes from './ListRow.scss';

export interface ListRowProps<T extends Data> {
  /** Spacing between columns */
  columnSizes: string;
  /** Header row height */
  columnGap: string;
  /** List row height */
  rowHeight?: string;
  /** Size of action button and checkbox */
  actionSize: string;
  /** Horizontal alignment of text (default: left) */
  horizontalTextAlign?: 'left' | 'right' | 'center';
  /** Vertical alignment of text (default: right) */
  verticalTextAlign?: 'start' | 'center' | 'end';
  /** If set to true, column text overflow will be wrapped to a new line. Otherwise, it will be truncated with an ellipsis (default: false) */
  textWrap?: boolean;
  /** List data */
  data: T;
  /** The column definition */
  columns: Column<T>[];
  /** Whether or not the item is selected (default: false) */
  itemSelected?: boolean;
  /** Whether or not the item is a trigger for pagination (default: false) */
  isTrigger?: boolean;
  /** Determines which selection mode the list is in. (default: ListSelectMode.None) */
  selectionMode?: ListSelectMode;
  /** Defines whether an action button will be rendered (default: true) */
  showActionButton?: boolean;
  /** Defines whether a check mark action button will be rendered (default: true) */
  showCheckMark?: boolean;
  /** Defines whether a checkbox for each item should be rendered (default: false) */
  showItemCheckbox?: boolean;
  /** Defines whether a row is disabled (default: false) */
  isRowDisabled?: boolean;

  /** Used for when a row is clicked. This can be either a callback function that will be executed
   * or a URL where the user should be navigated to.
   * When the list should navigate the user to e.g. a details page of a entry it is recommended to
   * directly pass the URL here instead of a callback function that does the routing in JavaScript. */
  onItemClicked?: LocationDescriptor<unknown> | ((data: T) => void);
  /**
   * A function that is getting called, when the selection state of the row changes.
   */
  onItemSelected?: (checked: boolean) => void;
  /**
   * A function that is getting called, when the element acts as a trigger (`isTrigger = true`) and gets into view.
   */
  onTriggered?: () => void;
  /** Provide inline actions which are available through '...' context menu */
  inlineMenuActions?: (data: T) => ActionData[];
}

let dateFormatter: Intl.DateTimeFormat;

let numberFormatter: Intl.NumberFormat;

export const setLocale = (locale: string): void => {
  dateFormatter = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });

  numberFormatter = new Intl.NumberFormat(navigator.language);
};

setLocale(navigator.language);

const renderData = <T extends Data>(
  column: Column<T>,
  rowData: T,
): { columnData: React.ReactNode; tooltip: string | undefined } => {
  const getTooltip = (value: unknown): string | undefined =>
    column.tooltip !== false ? getTooltipText(value) : undefined;

  if (!column.propertyName) {
    const columnData = column.render?.(undefined, rowData);
    return {
      columnData,
      tooltip: getTooltip(columnData),
    };
  }
  const value: unknown = rowData[column.propertyName];
  if (column.render) {
    const columnData = column.render(value, rowData);
    return {
      columnData,
      tooltip: getTooltip(columnData),
    };
  }

  if (value === null || value === undefined) {
    return {
      columnData: (
        <span
          data-test-id={`list-entry-property:${column.propertyName as string}`}
        />
      ),
      tooltip: undefined,
    };
  }

  if (typeof value === 'number') {
    const columnData = numberFormatter.format(value);
    return {
      columnData: (
        <span
          data-test-id={`list-entry-property:${column.propertyName as string}`}
        >
          {columnData}
        </span>
      ),
      tooltip: getTooltip(columnData),
    };
  }

  if (value instanceof Date) {
    const columnData = dateFormatter.format(value);
    return {
      columnData: (
        <span
          data-test-id={`list-entry-property:${column.propertyName as string}`}
        >
          {columnData}
        </span>
      ),
      tooltip: getTooltip(columnData),
    };
  }

  return {
    columnData: (
      <span
        data-test-id={`list-entry-property:${column.propertyName as string}`}
      >
        {String(value)}
      </span>
    ),
    tooltip: getTooltip(String(value)),
  };
};

/**
 * Renders the rows for the list component
 * @example
 * <ListRow
 *  key={index}
 *  data={[{id: '1',desc: 'Description 1',title: 'Item 1'}]}
 *  columnSizes={'1fr'}
 *  columnGap={'5px'}
 *  rowHeight={'50px'}
 *  horizontalTextAlign={'left'}
 *  verticalTextAlign={'center'}
 * />
 */
export const ListRow = <T extends Data>({
  columnSizes,
  columnGap,
  actionSize,
  horizontalTextAlign = 'left',
  verticalTextAlign = 'center',
  textWrap = false,
  rowHeight = textWrap ? 'auto' : '50px',
  data,
  itemSelected = false,
  isTrigger = false,
  columns,
  selectionMode = ListSelectMode.None,
  showActionButton = true,
  showCheckMark = false,
  showItemCheckbox = false,
  onItemClicked = noop,
  onItemSelected = noop,
  onTriggered = noop,
  isRowDisabled = false,
  inlineMenuActions,
}: PropsWithChildren<ListRowProps<T>>): JSX.Element => {
  const customRootStyles = {
    gridAutoRows: `minmax(50px, ${rowHeight})`,
    gridColumnGap: columnGap,
    justifyItems: horizontalTextAlign,
    alignItems: verticalTextAlign,
    gridTemplateColumns: columnSizes,
  } as React.CSSProperties;

  // Trigger based on: https://www.youtube.com/watch?v=NZKUirTtxcg
  const onTriggeredHandler = useCallback(() => {
    onTriggered && onTriggered();
  }, [onTriggered]);
  const observer = useRef<IntersectionObserver>();
  const elementRef = useCallback(
    (node: HTMLDivElement) => {
      if (isTrigger === false) {
        return;
      }
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          onTriggeredHandler();
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [isTrigger, onTriggeredHandler],
  );

  const onItemClickedHandler = useCallback(
    (data: T) => {
      // Do nothing if row is disabled
      if (isRowDisabled) {
        return;
      }

      // Emit onItemSelected only if list is in Multi mode
      if (selectionMode === ListSelectMode.Multi) {
        onItemSelected(!itemSelected);
        // Emit onItemClicked for all other modes
      } else if (typeof onItemClicked === 'function') {
        onItemClicked(data);
      }
    },
    [isRowDisabled, itemSelected, onItemClicked, onItemSelected, selectionMode],
  );

  const isLinkable: boolean =
    selectionMode === ListSelectMode.None &&
    typeof onItemClicked !== 'function';

  const generateCells: JSX.Element[] = columns.map((column: Column<T>) => {
    const { columnData, tooltip } = renderData<T>(column, data);

    return (
      <div
        key={column.key ?? (column.propertyName as string)}
        className={classes.cellWrapper}
      >
        <div
          className={clsx(classes.cell, { [classes.nowrap]: !textWrap })}
          title={tooltip}
          style={{
            justifySelf: column.horizontalColumnAlign, // Horizontal alignment based on column config
            alignSelf: verticalTextAlign, // Vertical alignment based on props
            textAlign: horizontalTextAlign, // Additional text alignment inside the cell
          }}
        >
          {columnData}
        </div>
      </div>
    );
  });

  const inlineActionMenuData = inlineMenuActions
    ? inlineMenuActions(data)
    : undefined;

  const Row = (
    <div
      className={clsx(classes.columnsRoot, {
        [classes.selected]: itemSelected,
        [classes.disabled]: isRowDisabled,
      })}
      style={customRootStyles}
      ref={isTrigger ? elementRef : null}
      data-test-id="list-entry"
    >
      {/* Items */}
      {isLinkable ? (
        <Link
          to={onItemClicked as string}
          className={clsx(classes.content, classes.link)}
        >
          {generateCells}
        </Link>
      ) : (
        <div
          className={classes.content}
          onClick={() => onItemClickedHandler(data)}
        >
          {generateCells}
        </div>
      )}
      {(inlineMenuActions ||
        showActionButton ||
        showCheckMark ||
        showItemCheckbox) && (
        <div className={classes.actions}>
          {inlineMenuActions &&
            inlineActionMenuData &&
            inlineActionMenuData.length > 0 && (
              <InlineMenu
                actions={inlineActionMenuData}
                showArrow={false}
                placement="bottom-end"
              />
            )}
          {showActionButton && selectionMode === ListSelectMode.None && (
            <>
              {showActionButton &&
                (typeof onItemClicked !== 'function' ? (
                  <Button
                    icon={IconName.ChevronRight}
                    height={actionSize}
                    width={actionSize}
                    path={onItemClicked}
                    dataTestId="list-entry-action"
                  />
                ) : (
                  <Button
                    icon={IconName.ChevronRight}
                    height={actionSize}
                    width={actionSize}
                    onButtonClicked={() => onItemClickedHandler(data)}
                    dataTestId="list-entry-action"
                  />
                ))}
            </>
          )}

          {showCheckMark && (
            <Button
              icon={IconName.Checkmark}
              height={actionSize}
              width={actionSize}
              dataTestId="list-entry-select-button"
              className={classes.selectionCheckMark}
              onButtonClicked={() => onItemClickedHandler(data)}
            />
          )}
          {showItemCheckbox && (
            <ListCheckBox
              height={actionSize}
              width={actionSize}
              onCheckBoxToggled={() => onItemClickedHandler(data)}
              isChecked={itemSelected}
              isDisabled={isRowDisabled}
            />
          )}
        </div>
      )}
    </div>
  );

  return Row;
};

import React, { useEffect, useState } from 'react';
import MosaicBG from '../../resources/images/bg.svg';
import mosaicLogo from '../../resources/images/mosaic_logo.svg';
import { useIdentityService } from '../IdentityServiceProvider/IdentityServiceProvider';
import { BrandingOptions } from '../common/BrandingOptions';
import classes from './CompletePasswordReset.module.scss';

const DEFAULT_BG_IMAGE = MosaicBG;

export interface ForgotPasswordProps {
  brandingOptions?: BrandingOptions;
}
/**
 * Renders the CompletePasswordReset component
 */
export const CompletePasswordReset: React.FC<ForgotPasswordProps> = ({
  brandingOptions,
}) => {
  const { completePasswordReset, getWellKnownUrls } = useIdentityService();

  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [axAuthManagementGraphQlEndpoint, setAxAuthManagementGraphQlEndpoint] =
    React.useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isReset, setIsReset] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const background = brandingOptions?.background ?? DEFAULT_BG_IMAGE;

  const backgroundStyles = {
    background: background?.includes('/') ? `url(${background})` : background,
    backgroundSize: `cover`,
    backgroundRepeat: `no-repeat`,
  };

  useEffect(() => {
    (async () => {
      const wellKnownUrls = await getWellKnownUrls();
      setAxAuthManagementGraphQlEndpoint(
        wellKnownUrls.axAuthManagementGraphQlEndpoint,
      );
    })();
  }, []);

  const callCompletePasswordReset = async (): Promise<void> => {
    setErrorMessage(undefined);
    const resetOtp = window.location.pathname.split('/').pop() ?? '';
    if (resetOtp === '' || resetOtp === undefined) {
      setErrorMessage('Invalid reset link');
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }
    setIsSubmitted(true);
    if (axAuthManagementGraphQlEndpoint !== undefined) {
      const completePasswordResetResponse = await completePasswordReset(
        resetOtp,
        password,
        axAuthManagementGraphQlEndpoint,
      );
      setIsSubmitted(false);
      if (completePasswordResetResponse.isSuccess) {
        setIsReset(true);
      } else {
        setErrorMessage(
          completePasswordResetResponse.errorMessage ??
            'Unexpected Error occurred while attempting to complete password reset. Please try again later.',
        );
      }
    } else {
      setErrorMessage('AxAuthManagementGraphQlEndpoint is not available');
    }
  };

  return (
    <div
      className={classes.container}
      style={backgroundStyles}
      data-test-id="complete-password-reset-page"
    >
      <div className={classes.whiteBox}>
        <div className={classes.logo}>
          <img
            alt="Customer logo"
            src={brandingOptions?.companyLogo ?? mosaicLogo}
          />
        </div>
        <div className={classes.content}>
          {errorMessage && (
            <div className={classes.errorMessage}>{errorMessage}</div>
          )}
          {isReset && (
            <>
              <p>Your password has been successfully reset.</p>
              <div
                className={classes.button}
                onClick={() => window.location.assign('/')}
              >
                Back to Sign In
              </div>
            </>
          )}
          {!isReset && (
            <>
              <p className={classes.hint}>Please enter the new password.</p>
              <div className={classes.inputGroup}>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  id="email"
                  placeholder="new password"
                />
              </div>
              <div className={classes.inputGroup}>
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  id="email"
                  placeholder="confirm new password"
                />
              </div>
              <div
                className={
                  isSubmitted ? classes.buttonDisabled : classes.button
                }
                onClick={() => callCompletePasswordReset()}
              >
                Change Password
              </div>
              <div className={classes.forgotPassword}>
                <a href="/">Back to Sign In</a>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

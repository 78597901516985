import { createContext } from 'react';
import { noop } from '../../../helpers/utils';

export interface PageHeaderActionsGroupContextType {
  registerCollapse: (readonly: () => void, uuid: string) => void;
  collapseAll: () => void;
}

export const PageHeaderActionsGroupContext =
  createContext<PageHeaderActionsGroupContextType>({
    registerCollapse: noop,
    collapseAll: noop,
  });
